@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  composes: h4 from global;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 17px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  composes: h5 from global;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  composes: buttonFont from global;
}

.subTotalLineItem {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  composes: h4 from global;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-top: 27px;
    padding-bottom: 1px;
  }
}

.itemLabel {
  composes: marketplaceSmallFontStyles from global;
  width: 300px;
}
.itemQuantity {
  width: 60px;
}

.itemUnits {
  width: 70px;
}

.itemUnitCost {
  width: 70px;
}

.itemAmount {
  width: 100px;
  text-align: right;
}

.itemValue {
  composes: marketplaceSmallFontStyles from global;
  margin: 0 0 0 0px;
  width: 100px;

  text-align: right;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  composes: smallFonmarketplaceSmallFontStylestStyles from global;
}

.totalPrice {
  composes: buttonFont from global;
  margin: 0 0 0 10px;
  padding-top: 0px;
}

.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.container {
  line-height: 1.4;
  text-align: center;
  padding: 44px;
  color: #333;
}

.circleSketchHighlight {
  position: relative;
  left: 0.5em;

  font-weight: 500;
}
.circleSketchHighlight:before {
  content: '';
  z-index: -1;
  left: 0em;
  top: -0.1em;
  border-width: 2px;
  border-style: solid;
  border-color: #ef8c22;
  position: absolute;
  border-right-color: transparent;
  width: 100%;
  height: 1em;
  transform: rotate(2deg);
  opacity: 0.7;
  border-radius: 50%;
  padding: 0.1em 0.25em;
}
.circleSketchHighlight:after {
  content: '';
  z-index: -1;
  left: 0em;
  top: 0.1em;
  padding: 0.1em 0.25em;
  border-width: 2px;
  border-style: solid;
  border-color: #ef8c22;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  width: 100%;
  height: 1em;
  transform: rotate(-1deg);
  opacity: 0.7;
  border-radius: 50%;
}

.lineItemOrderedWeight span {
  font-size: 12px;
}
.lineItemOrderedWeight span {
  font-size: 12px;
}