@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 35px 15px 82px 15px;
    background-color: var(--matterColorLight);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--matterColorNegative);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 30px 15px 82px 59px;
  }
}

.holdingImage {

  background-image: url('https://platter-product-assets-live.s3.eu-west-1.amazonaws.com/adduser-bg.png');

  width: 850px;
  height: 480px;
  background-size: 100%;
}

.holdingImageSuppliers {

  background-image: url('https://platter-product-assets-live.s3.eu-west-1.amazonaws.com/adduser-bg.png');

  width: 850px;
  height: 480px;
  background-size: 100%;
}
.tryForFree {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0px 10px 0px 10px;

  background-color: var(--marketplaceColor);
  line-height: 34px;
  min-height: 20px;
  width: 150px;
  border: 1px solid var(--marketplaceColorDark);
}

.tryForFree:hover {
  background-color: var(--marketplaceColorDark);
  border: 1px solid var(--marketplaceColorDark);
}

.helpCopy {
  max-width: 242px;
  display: inline-block;
  float: right;
  margin-top: 76px;
  margin-right: 116px;
}

.helpCopy span {
  color: var(--marketplaceColor);
}

.helpCopy p {
  font-size: 17px;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.bookDemo {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0px 10px 0px 10px;

  background-color: #ddfae8;
  line-height: 34px;
  min-height: 20px;
  width: 150px;
  margin-top: 10px;
  color: var(--marketplaceColorDark);
  border: 1px solid var(--marketplaceColorDark);
}

.bookDemo:hover {
  background-color: var(--successColor);
  color: var(--marketplaceColorDark);
  border: 1px solid var(--marketplaceColorDark);
}
.subNav {
  font-size: 10px;
  min-width: 20px;
  height: 20px;
  border-radius: 12px;
  top: -4px;
  padding: 2px 5px 4px 6px;
  position: relative;
}
button {
  padding: 0px;
  border: 0px;
}

button:hover {
  text-decoration: underline;
  cursor: pointer;
}
