.avatarBase {
  font-family: 'matter', Helvetica, Arial, sans-serif;
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-size: 40px;
  color: var(--matterColorLight);

  &:hover {
    text-decoration: none;
  }
}

/* Small Avatar */

.root {
  composes: avatarBase;

  /* Layout */
  width: 40px;
  height: 40px;
}

.initials {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--marketplaceColor);
  display: block;
  color: var(--marketplaceColorDark);
}

.initials span {
  position: relative;
  top: 4px;
}

.root .initials span {
  position: relative;
  top: 4px;
  left: 10px;
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

/* Medium Avatar */

.smallAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 24px;
  height: 24px;
}

.mediumAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 60px;
  height: 60px;
}

.mediumAvatar .initials {
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
}

/* Large Avatar */

.largeAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 96px;
  height: 96px;
}

.largeAvatar .initials span {
  position: relative;
  top: 32px;
  text-align: center;
  width: 100px;
  display: block;
}

.largeAvatar .initials {
  width: 100px;
  font-size: 30px;
  font-weight: var(--fontWeightSemiBold);
  height: 100px;
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}
.topnavAvatar {
}
