@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 35px 15px 82px 15px;
    background-color: var(--matterColorLight);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--matterColorNegative);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 30px 15px 82px 59px;
  }
}

.tabs {
  padding-top: 2px;
  border-bottom: 1px var(--matterColorNegative) solid;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin: 0 0 35px;
  @media (--viewportLarge) {
    flex-direction: column;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}
.subNav {
  font-size: 10px;
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  top: -5px;
  padding: 0px 3px 2px 4px;
  position: relative;
}
