@import '../../../styles/customMediaQueries.css';

.quantityField {
  width: 78%;
  display: inline;
  float: left;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.deliveryField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 52px;
  }
}

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
}

.error {
  composes: h4 from global;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.description {
  font-size: 16px;
  line-height:normal;
  margin-top: -15px;
  margin-bottom: 20px;
  padding: 10px;
  font-weight: normal;
  background: rgb(244, 195, 195)
}
.inlineSubmitButton {
  display: inline;
  float: left;
  width: 16%;
  margin-left: 10px;
}
.inlineSubmitButton button {
  font-size: 34px !important;



}


.inlineSubmitButton .buttonFont span {
  margin-top: -5px;
  display: block;
}