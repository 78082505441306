.goback {
  border: none;
}

.goback:hover {
  cursor: pointer;
  text-decoration: underline;;
}

.goback,
.gobackInactive {
  margin-top: -34px;
  margin-right: 30px;
  display: inline;
  float: right;
  width: 92%;
  text-align: -webkit-right;
}
.gobackInactive {
  border: none;
  color: lightgray;

}

