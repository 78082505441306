@import '../../../styles/customMediaQueries.css';

.quantityField {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.deliveryField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 52px;
  }
}

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
}

.error {
  composes: h4 from global;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.description {
  font-size: 16px;
  line-height:normal;
  margin-top: -15px;
  margin-bottom: 20px;
  padding: 10px;
  font-weight: normal;
  background: rgb(244, 195, 195)
}
.quantityFieldHidden {
  display: inline;
 left: -999999
}

.quantityField textarea{
  resize: auto;
  height: 200px !important;
}