@import '../../../styles/customMediaQueries.css';

.root {
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  composes: h4 from global;
  border-bottom-width: 0;
  margin: 0;
  padding: 2px 0 8px 0;
  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent;

  &::placeholder {
    padding: 0 0 0 0;
  }

  @media (--viewportMedium) {
    margin: 0;
  }

  @media (--viewportLarge) {
    padding: 0 0 6px 0;
    margin: 0;
    width: 100%;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: row;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  composes: button buttonText buttonBordersSecondary buttonColorsSecondary from global;

  font-family: 'matter', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 17px 0 0 0;
  width: auto;
}

.sendIcon {
  margin: -3px 5px 0 0;
}

.itemValue {
  float: right;
  clear: both;
}

div.lineItem {
  clear: both;
  margin-bottom: 80px;
}

.lineItemSubLabel,
.lineItemPickedItemLabel {
  font-size: 12px;
  font-weight: normal;
}

.lineItemPickedItemLabel .itemValue {
  font-weight: bold;
  font-size: 12px;
}

.lineItemPickedItemUpdate .updatePickedWeight {
  float: left;
  display: flex;
  font-size: 12px;
}

.lineItemPickedItemUpdate .updatePickedWeight textarea {
  float: left;
  display: flex;
  height: 25px !important;
  line-height: 25px;
  font-size: 12px;
  min-width: 200px;
}

.lineItemPickedItemUpdate .submitButton {
  margin: 0px 0px 0px 10px;
  font-size: 12px;
  min-width: 70px;
  float: left;
}

.removeButton {
  font-size: 12px;
  border: none;
  text-decoration: underline;
  color: var(--);
  cursor: pointer;
  margin-left: -5px;
}
.removeButton:hover {
  text-decoration: none;
}

.hiddenField {
  display: none;
}

.itemLabel {
  composes: marketplaceSmallFontStyles from global;
  width: 300px;
}
.itemQuantity {
  width: 60px;
}

.itemUnits {
  width: 70px;
}

.itemUnitCost {
  width: 70px;
}

.itemAmount {
  width: 100px;
  text-align: right;
}

.itemValue {
  composes: marketplaceSmallFontStyles from global;
  margin: 0 0 0 0px;
  width: 100px;

  text-align: right;
}

.lineItem {
  composes: h4 from global;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 17px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
