@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  composes: h3 from global;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;
  line-height: 24px;
  color: var(--matterColor);
  margin-bottom: 0px;
}

.subTitle {
  composes: marketplaceDefaultFontStyles from global;
  line-height: 24px;
  color: var(--matterColor);
  margin-bottom: 0px;
  font-weight: normal;
}
.subTitleDisplayPrice {
  composes: marketplaceDefaultFontStyles from global;
  line-height: 24px;
  color: var(--matterColor);
  margin-bottom: 0px;
  font-weight: normal;
  min-height: 31px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  display: block;
  clear: both;
  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.description {
  font-size: 11px;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: normal;
  background: rgb(244, 195, 195);
  color: var(--matterColor);
  min-height: 53px;
}
.noProductSpec {
  padding-bottom: 23px;
}
.productSpecPdf {
  font-size: 11px;
  line-height: normal;
  margin-top: -32px;
  margin-bottom: 12px;

  padding: 10px;

  padding-bottom: 14px;
  font-weight: normal;
  background: rgb(244, 195, 195);
  color: var(--matterColor);
  min-height: 23px;
}

.productSpecPdf a {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

.productSpecPdf img {
  width: 12px;
  margin-left: 5px;
  margin-top: -4px;
}
.hideDescription {
  display: none;
}

.inlineSubmitButton {
  display: inline;
  float: left;
  width: 16%;
  margin-left: 10px;
}
.productCategoryHeading {
  width: 100%;
  display: block;
}
